import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react95';

const RandomProgress = ({message, step, setStep }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + Math.floor(Math.random() * 10) + 1;
        if (nextProgress >= 100) {
          clearInterval(interval);
          setStep(step + 1)
          return 100;
        }
        return nextProgress;
      });
    }, 180); // adjust speed here

    return () => clearInterval(interval); // clean up interval on unmount
  }, [step, setStep]);

  return (
    <div style={{ width: '300px' }}>
      <div>{message}</div>
      <ProgressBar width={300} value={progress} />
    </div>
  );
};

export default RandomProgress;
