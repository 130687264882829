import React, { useState, useEffect } from 'react';
import {
  Button,
  Frame,
  Toolbar,
  Window,
  WindowContent,
  WindowHeader,
  styleReset
} from 'react95';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import ContentWindow from './pages/ContentWindow'
import RandomProgress from './pages/RandomProgress'
import CongratsWindow from './pages/CongratsWindow'
import ContentWindowFalse from './pages/ContentWindowFalse'

import { getFirestore, query, orderBy, getDocs } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

/* Pick a theme of your choice */
import original from 'react95/dist/themes/original';
import './App.css';

/* Original Windows95 font (optional) */
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFzv1c-0VXRsawwu_bMjR7ebFd4tDUXl8",
  authDomain: "dating-fcc3a.firebaseapp.com",
  projectId: "dating-fcc3a",
  storageBucket: "dating-fcc3a.appspot.com",
  messagingSenderId: "725497374894",
  appId: "1:725497374894:web:890140bd71fa4005d0e53f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);




const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 600;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
  }
`;


function App() {
  // Define a state variable to keep track of the global value
  const [step, setStep] = useState(-1);

  const [startDate, setStartDate] = useState(new Date());

  const [isLatestDoc, setIsLatestDoc] = useState(false);

  // Run a function when the page first loads
  useEffect(() => {
    const fetchDocuments = async () => {
      const baseCollectionRef = collection(db, "base");

      // Query the collection, ordering by 'datetime' field in descending order (latest first)
      const q = query(baseCollectionRef, orderBy("datetime", "desc"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // No documents found, call function0
        // function0();
      } else {
        // There is at least one document, get the latest (first) document
        const latestDoc = querySnapshot.docs[0];
        setIsLatestDoc(true)
        setStartDate(latestDoc.data().datetime.toDate())
        setStep(19)
      }
    };
    fetchDocuments();
  }, []); // The empty array ensures this only runs once, when the component mounts

  // Run a function when the page first loads
  useEffect(() => {
    if (step >= 19 && !isLatestDoc) {
      var newDateTime = new Date()
      setStartDate(newDateTime)
      insertNewDatetime(newDateTime)
    }
  }, [step]); // The empty array ensures this only runs once, when the component mounts

  const insertNewDatetime = (newDateTime) => {
    // Reference to the "users" collection
    const baseCollectionRef = collection(db, "base");

    // Add a document to the "users" collection
    addDoc(baseCollectionRef, {
      datetime: newDateTime
    })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  return (
    <div class="full-page">
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <div className="wrapper">
          <Window className='window'>
            <WindowHeader className='window-title'>
              <span>whoarewe.exe</span>
            </WindowHeader>
            {step == -1 && <RandomProgress message='Loading...' step={step} setStep={setStep} />}

            {step == 0 && <ContentWindow textContent='There are two types of people in this world: the Zoës and the Zeldas. Zoës are the ones who like order, plans, and consistency. Zeldas? Well, they’re the free spirits, always embracing chaos and spontaneity. But life is a mix of both, and sometimes you need a bit of each to find your perfect balance. Let’s see where you fall on this little adventure, and maybe we’ll discover something together' image={require("./img/0.jpg")} lButton="Let's go!" rButton="Onward to adventure!!" step={step} setStep={setStep} />}

            {step == 1 && <RandomProgress message='Calculating your adventurous spirit...' step={step} setStep={setStep} />}

            {step == 2 && <ContentWindow textContent='You’re on an adventure. What are you more drawn to?' image={require("./img/1.jpg")} lButton="Finding a cozy spot to relax and take in the moment" rButton="Exploring every corner, seeing what surprises you can find" step={step} setStep={setStep} />}

            {step == 3 && <RandomProgress message='Analyzing your market wanderlust...' step={step} setStep={setStep} />}

            {step == 4 && <ContentWindow textContent='As you walk through a bustling market, would you:' image={require("./img/2.jpg")} lButton="Make a beeline for something you’ve been wanting to find" rButton="Wander aimlessly, letting the adventure guide you" step={step} setStep={setStep} />}

            {step == 5 && <RandomProgress message='Watching the sunset unfold in your mind...' step={step} setStep={setStep} />}

            {step == 6 && <ContentWindow textContent='You reach a quiet beach at sunset. Do you:' image={require("./img/3.jpg")} lButton="Sit quietly, enjoying the peace and beauty" rButton="Run into the water, embracing the thrill of the moment" step={step} setStep={setStep} />}

            {step == 7 && <RandomProgress message='Choosing the perfect night under the stars...' step={step} setStep={setStep} />}

            {step == 8 && <ContentWindow textContent='Night falls, and you need to decide where to stay. Do you:' image={require("./img/4.jpg")} lButton="Book a cute, organized bed-and-breakfast nearby" rButton="Go off the grid and camp under the stars" step={step} setStep={setStep} />}

            {step == 9 && <RandomProgress message='Simulating your reaction to the unexpected...' step={step} setStep={setStep} />}

            {step == 10 && <ContentWindow textContent='The morning brings a chance for a big new challenge. Do you:' image={require("./img/5.jpg")} lButton="Prepare for it, making sure you have everything planned" rButton="Jump in headfirst, figuring it out as you go" step={step} setStep={setStep} />}

            {step == 11 && <RandomProgress message='Measuring your connection to new encounters...' step={step} setStep={setStep} />}

            {step == 12 && <ContentWindow textContent='In the middle of your journey, you meet someone special. Would you:' image={require("./img/6.jpg")} lButton="Spend quiet time getting to know them deeply" rButton="Go on wild, spontaneous adventures together" step={step} setStep={setStep} />}

            {step == 13 && <RandomProgress message='Exploring the depths of your heart...' step={step} setStep={setStep} />}

            {step == 14 && <ContentWindow textContent='After traveling together for a while, do you:' image={require("./img/7.jpg")} lButton="Slowly build trust, seeing how things develop" rButton="Dive into the excitement, letting feelings take over" step={step} setStep={setStep} />}

            {step == 15 && <RandomProgress message='Determining your commitment level...' step={step} setStep={setStep} />}

            {step == 16 && <ContentWindow textContent='Your adventure partner asks if you’re ready to make it official. Do you:' image={require("./img/8.jpg")} lButton="Take your time, make sure it’s right" rButton="Say yes when it feels good, because why wait?" step={step} setStep={setStep} />}

            {step == 17 && <RandomProgress message='Preparing to reveal your destiny...' step={step} setStep={setStep} />}

            {step == 18 && <ContentWindowFalse textContent='And now, as our little journey comes to an end, would you say:' image={require("./img/9.jpg")} lButton="We should keep wandering like we are" rButton="We should make this adventure something more—officially together?" step={step} setStep={setStep} />}

            {step >= 19 && <CongratsWindow startDate={startDate} />}

          </Window>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;