import React, { useEffect, useState } from 'react';
import {
    Button,
    Frame,
    Toolbar,
    Window,
    WindowContent,
    WindowHeader,
    styleReset
} from 'react95';

const ContentWindowFalse = ({textContent,image, lButton, rButton, step, setStep}) => {

    function increaseStep(){
        setStep(step +1)
    }
    return (
        <div>
            <img src={image} class="center"/>
            <WindowContent>
                <p>
                    {textContent}
                </p>
            </WindowContent>
            <div class="button-container">
                <div class="selection-button">
                    <Button size='lg'>
                        {lButton}
                    </Button>
                </div>
                </div>
                <div class="button-container">
                <div class="selection-button">
                    <Button size='lg' onClick={increaseStep}>
                        {rButton}
                    </Button>
                </div>
            </div>
            </div>
    );
};

export default ContentWindowFalse;
