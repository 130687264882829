import React, { useEffect, useState } from 'react';
import { format, intervalToDuration } from 'date-fns';
import {
    Button,
    Frame,
    Toolbar,
    Window,
    WindowContent,
    WindowHeader,
    styleReset
} from 'react95';

const CongratsWindow = ({ startDate }) => {
    const [elapsedTime, setElapsedTime] = useState('');
    const [formattedStartDate, setFormattedStartDate] = useState('');

    useEffect(() => {
        const calculateElapsedTime = () => {
            const now = new Date();

            const duration = intervalToDuration({ start: startDate, end: now });
            let formattedTime = '';

            // Construct formattedTime conditionally
            if (duration.years) formattedTime += `${duration.years} years, `;
            if (duration.months) formattedTime += `${duration.months} months, `;
            if (duration.days) formattedTime += `${duration.days} days, `;
            if (duration.hours) formattedTime += `${duration.hours} hours, `;
            if (duration.minutes) formattedTime += `${duration.minutes} minutes, `;
            if (duration.seconds) formattedTime += `${duration.seconds} seconds`;

            // Remove trailing comma and space if necessary
            formattedTime = formattedTime.replace(/,\s*$/, '');

            setElapsedTime(formattedTime);
        };

        const formatStartDate = () => {
            const formattedDate = format(startDate, "MMMM d, yyyy 'at' HH:mm:ss");
            setFormattedStartDate(formattedDate);
        };

        calculateElapsedTime();
        formatStartDate();
        const intervalId = setInterval(calculateElapsedTime, 1000); // Update every second

        return () => clearInterval(intervalId); // Clean up on component unmount
    }, [startDate]);

    return (
        <div>
            <img src={require("../img/congrats.gif")} alt="Congrats" className="center" />

            <WindowContent>
                <div>
                    <p>Celebrating our love for:</p>
                    <p>{elapsedTime}</p>
                    <br />
                    <p>We began this wonderful journey on:</p>
                    <p>{formattedStartDate} SGT</p>
                </div>
            </WindowContent>
        </div>
    );
};

export default CongratsWindow;
